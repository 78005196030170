@import '../../styles/propertySets.css';

.root {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.rules {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.moveInOutRow {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.moveInCell {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    width: 50%;
  }
}

.moveOutCell {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    width: 50%;
  }
}

.moveInTime {

}

.moveOutTime {

}

.tenantsAllowedRow {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.childrenCell {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    width: 33%;
  }
}

.infantsCell {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    width: 33%;
  }
}

.tenantsCell {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    width: 34%;
  }
}

.numberOfChildren {

}

.numberOfInfants {

}

.numberOfTenants {

}

.petPolicyTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 15px;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 20px;
    margin-bottom: 12px;
  }
}
